import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicantsInitialState, ApplicantsStateTypes } from './applicants.state.ts';
import {
  fetchApplicants,
  fetchSelectedApplicantSettingsPWA,
} from 'store/applicants/applicants.thunk.ts';
import { QueryState } from '@/common/enum';
import { ApplicantType } from 'interfaces/applicants.interface.ts';

const initialState: ApplicantsStateTypes = ApplicantsInitialState;

export const applicantsSlice = createSlice({
  name: 'applicants',
  initialState,
  reducers: {
    updateApplicantDataSet: (state, action: PayloadAction<ApplicantType[]>) => {
      state.applicants = action.payload;
    },
    updateSelectedApplicant: (state, action: PayloadAction<ApplicantType>) => {
      state.selectedApplicant = action.payload;
    },
    resetApplicant: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchApplicants.pending, (state) => {
        state.queryState = QueryState.AWAIT;
      })
      .addCase(fetchApplicants.fulfilled, (state, action) => {
        state.queryState = QueryState.READY;
        state.applicants = action.payload.applicants.dataset;
        if (
          action.payload.selectedApplicant &&
          Object.keys(action.payload.selectedApplicant).length
        ) {
          state.selectedApplicant = action.payload.selectedApplicant;
        }
      })
      .addCase(fetchApplicants.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message ?? 'Error fetching applicants';
      })
      .addCase(fetchSelectedApplicantSettingsPWA.fulfilled, (state, action) => {
        const dataResponse = action.payload;
        if (dataResponse && Array.isArray(dataResponse) && dataResponse.length) {
          const settings = dataResponse[0];
          state.userSelectedApplicantSettingID = settings.pvSettingID;
          const applicantObject = JSON.parse(settings.pvSettingValue);
          if (applicantObject && Object.keys(applicantObject).length) {
            state.selectedApplicant = applicantObject;
          }
        }
      });
  },
});

export const { resetApplicant } = applicantsSlice.actions;
export default applicantsSlice.reducer;
