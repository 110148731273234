/* eslint-disable @typescript-eslint/no-explicit-any,react-hooks/exhaustive-deps */
import { memo, useEffect, useState, useMemo } from 'react';
import { ErrorMessage, Typo } from 'components/primitive';
import { ErrorHandler } from 'components/fragment';
import Box from '@mui/material/Box';
import { selectAuthToken } from 'store/authToken/authToken.selector.ts';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import useParcelData from 'hooks/useParcelData.ts';
import { useForceRefresh } from 'pages/Map/hooks';
import type { AxiosRequestConfig } from 'axios';
import { MapPageViewProps } from './Map.props.ts';
import { useForm } from 'react-hook-form';
import { updateMapInfo } from 'store/map/map.action.ts';
import { mapInfoSelector } from 'store/map/map.selector.ts';
import { iaDataSetSelector, iaQueryStateSelector } from 'store/ia/ia.selector.ts';
import { paDataSetSelector, paQueryStateSelector } from 'store/pa/pa.selector.ts';
import { Nullable } from 'interfaces/Nullable.ts';
import { IADetails } from 'interfaces/ia.interface.ts';
import { PAData } from 'interfaces/pa.interface.ts';
import { ApplicantType } from 'interfaces/applicants.interface.ts';
import MapPage from './Map.view.tsx';
import {
  selectNonParcelMode,
  hasFetchedAllGroupsSelector,
  selectedGroupNameForParcels,
} from 'store/accountsInfo/accountsInfo.selector.ts';
import { selectConnectionHealth } from 'store/connection/connection.selector.ts';
import {
  applicantsSelector,
  selectedApplicantSelector,
} from 'store/applicants/applicants.selector.ts';
import { updateSelectedApplicantSettingsPWA } from 'store/applicants/applicants.thunk.ts';
import { updateSelectedApplicant } from 'store/applicants/applicants.action.ts';
import { fetchIADataSet } from 'store/ia/ia.thunk.ts';
import { fetchPADataSet } from 'store/pa/pa.thunk.ts';
import { shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import localforage from 'localforage';
import { findByQuery, deleteDocument } from 'store/db/db.thunk.ts';
import { findFileByQuery, deleteOfflineFile } from 'store/file_db/file_db.thunk.ts';
import { setIADataSet } from 'store/ia/ia.actions.ts';
import { setPADataSet } from 'store/pa/pa.actions.ts';
import { useLogout } from '@/hooks';
import { QueryState } from '@/common/enum';

// eslint-disable-next-line react-refresh/only-export-components
const MapPageContainer = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const categorySelected = useAppSelector(mapInfoSelector).category;
  const dropMarkerMode = useAppSelector(mapInfoSelector).dropMarkerMode;
  const quickAssessmentMode = useAppSelector(mapInfoSelector).quickAssessmentMode;
  const mapID = useAppSelector(mapInfoSelector).mapId;
  const countyGroupName = useAppSelector(selectedGroupNameForParcels);
  const authToken = useAppSelector(selectAuthToken);
  const [needsDownload, setNeedsDownload] = useState<boolean>(false);
  const [activeModal, setActiveModal] = useState<Nullable<string>>(null);
  const [metaData, setMetaData] = useState<any>({});
  const logout = useLogout();
  const [processingData, setProcessingData] = useState({
    applicant: false,
  });
  const [headerMenuAnchorEl, setHeaderMenuAnchorEl] = useState(null);
  const [confirmModalInfo, setConfirmModalInfo] = useState<{
    title: string;
    message: string;
    allowProceed: boolean;
  }>({
    title: '',
    message: '',
    allowProceed: false,
  });
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [parcelsToResetStyle, setParcelsToResetStyle] = useState<any[]>([]);

  const iaDataSet: Nullable<IADetails[]> = useAppSelector(iaDataSetSelector, shallowEqual);
  const paDataSet: Nullable<PAData[]> = useAppSelector(paDataSetSelector, shallowEqual);
  // todo add loading state
  const IaQueryState: Nullable<QueryState> = useAppSelector(iaQueryStateSelector, shallowEqual);
  const PaQueryState: Nullable<QueryState> = useAppSelector(paQueryStateSelector, shallowEqual);
  const isIAFetching = IaQueryState === QueryState.AWAIT;
  const isPAFetching = PaQueryState === QueryState.AWAIT;
  const isDataLoading = useMemo(() => isIAFetching || isPAFetching, [isIAFetching, isPAFetching]);
  const applicants = useAppSelector(applicantsSelector);
  const selectedApplicant = useAppSelector(selectedApplicantSelector);
  const health = useAppSelector(selectConnectionHealth);
  const nonParcelMode: boolean = !!useAppSelector(selectNonParcelMode);
  const hasFetchedGroups = useAppSelector(hasFetchedAllGroupsSelector);
  const [noCoordsPaData, setNoCoordsPaData] = useState<number>(0);
  const [hasCheckedPaDataForApplicantId, setHasCheckedPaDataForApplicantId] =
    useState<boolean>(false);
  const applicantsOptions =
    applicants?.map((applicant) => {
      return {
        label: applicant.applicant_name,
        value: applicant.id || '',
        withEndIcon: !!applicant?.state_wide,
      };
    }) ?? [];

  const {
    getParcelData,
    downloadParcel,
    parcelData,
    roadData,
    isFetching,
    centerLatLon: center,
  } = useParcelData(countyGroupName || '');
  const componentRefresh = useForceRefresh();
  const categoryOptions = [
    { label: 'Individual Assistance', value: 'ia' },
    { label: 'Public Assistance', value: 'pa' },
  ];

  interface FormValues {
    categoryOptions: any[];
    applicantsOptions: any[];
    isOpen: boolean;
    fieldName: string;
    category: string;
    applicantID: string;
  }

  const defaultValues: FormValues = {
    categoryOptions: [],
    applicantsOptions: [],
    isOpen: false,
    fieldName: '',
    category: '',
    applicantID: '',
  };

  const form = useForm({
    defaultValues: defaultValues,
  });
  // form.setValue('applicantID', selectedApplicant?.id ? selectedApplicant?.id : '');
  const data = { groupName: countyGroupName || '' };
  const isModalOpen = form.getValues('isOpen');
  const handleUpdateParcelData = async () => {
    //get all unsaved items from PouchDB
    const unsavedItems = (await findByQuery({
      selector: { isUnsaved: true },
    })) as any;
    //get all files associated with unsaved items
    const pvGlobalIdArray = unsavedItems.map((item: any) => item.pvGlobalID);
    const unsavedFiles = (await findFileByQuery({
      selector: { pvGlobalGroupID: { $in: pvGlobalIdArray } },
    })) as any;

    //loop unsavedFiles and delete them
    for (let i = 0; i < unsavedFiles.length; i++) {
      await deleteOfflineFile(unsavedFiles[i]);
    }

    //loop unsavedItems and delete them
    for (let i = 0; i < unsavedItems.length; i++) {
      await deleteDocument(unsavedItems[i]);
    }

    const getPouchIAData = (await findByQuery({
      selector: { dma_category: 'ia' },
    })) as any;
    dispatch(setIADataSet(getPouchIAData));

    const getPouchPAData = (await findByQuery({
      selector: { dma_category: 'pa' },
    })) as any;
    dispatch(setPADataSet(getPouchPAData));

    //set unsavedItems to state to know for which parcels to reset style
    setParcelsToResetStyle(unsavedItems);

    //delete all localforage keys
    const keys = await localforage.keys();
    const p = [];
    for (const key of keys) {
      p.push(localforage.removeItem(key));
    }
    await Promise.all(p);

    //re-fetch parcel data
    setNeedsDownload(true);
    handleDownloadParcel();
  };

  const handleLogOut = () => {
    setActiveModal('confirmLogout');
    if (health) {
      const modalInfo = {
        title: 'Logout',
        message: 'Are you sure you want to log out?',
        allowProceed: true,
      };
      setConfirmModalInfo(modalInfo);
    } else {
      const modalInfo = {
        title: 'Logout',
        message:
          'Can not log out while offline. Please check your internet connection and try again.',
        allowProceed: false,
      };
      setConfirmModalInfo(modalInfo);
    }
  };

  const downloadUserGuide = () => {
    const pdfUrl = '/MobileAssessment.pdf';

    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.download = 'User Guide Mobile Damage Assessment.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const returnAboutContent = () => {
    return (
      <Box>
        <Box>
          <Typo sx={{ fontSize: '24px!important' }}>Cloud EOC</Typo>
        </Box>
        <Box>
          <Typo sx={{ fontSize: '15px!important' }}>Version: {metaData.version}</Typo>
          <Typo sx={{ fontSize: '15px!important' }}>Date: {metaData.date}</Typo>
        </Box>
      </Box>
    );
  };

  const headerMenuOptions = [
    // {
    //   name: 'Select Applicant',
    //   onClick: () => {
    //     const selectOptions =
    //       applicants?.map((applicant) => {
    //         return {
    //           label: applicant.applicant_name,
    //           value: applicant.id,
    //         };
    //       }) ?? [];
    //
    //     form.setValue('isOpen', true);
    //     form.setValue('fieldName', 'applicantID');
    //     form.setValue('applicantsOptions', selectOptions);
    //     form.setValue('applicantID', selectedApplicant?.id ? selectedApplicant?.id : '');
    //     setActiveModal('applicants');
    //   },
    // },
    {
      name: 'Select Category',
      onClick: () => {
        form.setValue('isOpen', true);
        form.setValue('fieldName', 'category');
        form.setValue('categoryOptions', categoryOptions);
        form.setValue('category', categorySelected ? categorySelected : '');
        setActiveModal('category');
      },
    },
    {
      name: 'Select Team',
      onClick: () => {
        setActiveModal('team');
      },
    },
    {
      name: 'Update Parcel Data',
      onClick: () => {
        setActiveModal('confirmUpdate');
        const modalInfo = health
          ? {
              title: 'Update Parcel Data',
              message:
                'By updating the parcel data, all un-uploaded parcel entries will be lost. Do you wish to proceed?',
              allowProceed: true,
            }
          : {
              title: 'Update Parcel Data',
              message:
                'Update Parcel Data is not available while offline. Please check your internet connection and try again.',
              allowProceed: false,
            };
        setConfirmModalInfo(modalInfo);
      },
    },
    {
      name: 'User Guide',
      onClick: () => {
        downloadUserGuide();
      },
    },
    {
      name: 'About',
      onClick: () => {
        const modalInfo = {
          title: 'About',
          message: '',
          children: returnAboutContent(),
          allowProceed: false,
        };
        setConfirmModalInfo(modalInfo);
        setActiveModal('about');
      },
    },
    {
      name: 'Log Out',
      onClick: () => handleLogOut(),
    },
  ];

  const handleHeaderMenuClick = (e: any) => {
    setHeaderMenuAnchorEl(e.target);
  };

  const handleHeaderCloseMenu = () => {
    setHeaderMenuAnchorEl(null);
  };

  const processSelectedApplicant = async (applicant: ApplicantType) => {
    return await dispatch(updateSelectedApplicantSettingsPWA(applicant)).unwrap();
  };

  const checkPADataNullCoordinates = () => {
    if (paDataSet?.length) {
      const nullCoordinatesItems = paDataSet.filter((pa) => {
        return !pa.pvParcelGeometryObject && (!pa.latitude || !pa.longitude);
      });
      if (nullCoordinatesItems?.length) {
        setNoCoordsPaData(nullCoordinatesItems.length);
      }
    }
  };

  const setApplicantData = async (applicantData: ApplicantType) => {
    setProcessingData((prevState) => ({ ...prevState, applicant: true }));
    if (health) {
      await processSelectedApplicant(applicantData);
      await dispatch(fetchPADataSet());
    }
    dispatch(updateSelectedApplicant(applicantData));
    setProcessingData((prevState) => ({ ...prevState, applicant: false }));
  };

  //   const handleModalAction = async () => {
  //   const data = form.getValues();
  //   if (data.fieldName === 'category') {
  //     form.setValue('isOpen', false);
  //     dispatch(updateMapInfo({ category: data.category }));
  //     form.setValue('fieldName', 'applicantID');
  //     setActiveModal('applicants');
  //     dispatch(updateMapInfo({ quickAssessmentMode: false }));
  //     if (data.category === 'pa') {
  //       const applicantID = data.applicantID;
  //       const applicantData = applicants?.find((applicant) => applicant.id === applicantID);
  //       if (applicantData && Object.keys(applicantData)?.length) {
  //         await setApplicantData(applicantData);
  //       }
  //     }
  //     setActiveModal(null);
  //   }
  // };

  const handleModalAction = async () => {
    form.setValue('isOpen', false);
    const data = form.getValues();
    if (data.fieldName === 'category') {
      dispatch(updateMapInfo({ category: data.category }));
      // form.setValue('fieldName', 'applicantID');
      // setActiveModal('applicants');
      dispatch(updateMapInfo({ quickAssessmentMode: false }));
      if (data.category === 'pa') {
        const applicantID = data.applicantID;
        const applicantData = applicants?.find((applicant) => applicant.id === applicantID);
        if (applicantData && Object.keys(applicantData)?.length) {
          await setApplicantData(applicantData);
        }
      }
      setActiveModal(null);
    }
    if (data.fieldName === 'applicantID') {
      const applicantID = data.applicantID;
      const applicantData = applicants?.find((applicant) => applicant.id === applicantID);
      if (applicantData && Object.keys(applicantData)?.length) {
        await setApplicantData(applicantData);
      }
      if (!categorySelected) {
        setActiveModal('category');
        form.setValue('fieldName', 'category');
        form.setValue('categoryOptions', categoryOptions);
        form.setValue('isOpen', true);
      } else {
        setActiveModal(null);
      }
    }
  };

  const handleDownloadParcel = () => {
    if (!authToken) return;
    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'application/json', Authorization: authToken },
      params: { access_token: authToken },
    };
    downloadParcel(config, nonParcelMode).then(() => {
      setNeedsDownload(false);
      componentRefresh();
    });
  };

  const handleOnConfirm = async () => {
    if (activeModal === 'confirmUpdate') {
      setConfirmLoading(true);
      await handleUpdateParcelData();
      setConfirmLoading(false);
    } else if (activeModal === 'confirmLogout') {
      setConfirmLoading(true);
      logout();
      setConfirmLoading(false);
    }
    setActiveModal(null);
  };

  useEffect(() => {
    const fetchMetaData = async () => {
      const metadata = await fetch('/meta.json');
      const data = await metadata.json();
      setMetaData(data);
    };
    // noinspection JSIgnoredPromiseFromCall
    fetchMetaData();
  }, []);

  useEffect(() => {
    if (!isFetching && ((!parcelData && !roadData) || (nonParcelMode && !roadData))) {
      getParcelData(nonParcelMode).then((data) => {
        if (data) {
          setNeedsDownload(false);
        } else {
          setNeedsDownload(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isIAFetching || isPAFetching) return;
    const fetchData = async () => {
      await dispatch(fetchIADataSet());
      await dispatch(fetchPADataSet());
    };
    // noinspection JSIgnoredPromiseFromCall
    fetchData();
  }, []);

  useEffect(() => {
    if (PaQueryState === QueryState.READY && !processingData.applicant) {
      if (paDataSet?.length) {
        checkPADataNullCoordinates();
        if (!selectedApplicant && hasCheckedPaDataForApplicantId) {
          //get the applicant_id from the first item in the paDataSet
          const firstItem = paDataSet[0];
          const applicantID = firstItem?.applicant_id?.toString();
          if (applicantID && applicants?.length) {
            const applicantData = applicants?.find((applicant) => applicant.id === applicantID);
            if (applicantData && Object.keys(applicantData)?.length) {
              setApplicantData(applicantData).then(() => {
                setHasCheckedPaDataForApplicantId(true);
              });
            }
          }
        }
      } else {
        setHasCheckedPaDataForApplicantId(true);
      }
    }
  }, [paDataSet, PaQueryState, processingData.applicant]);

  useEffect(() => {
    if (!selectedApplicant && hasCheckedPaDataForApplicantId) {
      if (isModalOpen && activeModal === 'applicants') return;
      //no applicant selected, fetch applicants
      const selectOptions =
        applicants?.map((applicant) => {
          return {
            label: applicant.applicant_name,
            value: applicant.id,
            withEndIcon: !!applicant?.state_wide,
          };
        }) ?? [];

      form.setValue('isOpen', true);
      form.setValue('fieldName', 'applicantID');
      form.setValue('applicantsOptions', selectOptions);
      setActiveModal('applicants');
    } else if (selectedApplicant && categorySelected === null) {
      form.setValue('applicantID', selectedApplicant?.id ? selectedApplicant?.id : '');
      if (isModalOpen && activeModal === 'category') return;
      form.setValue('isOpen', true);
      form.setValue('fieldName', 'category');
      form.setValue('categoryOptions', categoryOptions);
      setActiveModal('category');
    }
  }, [selectedApplicant, categorySelected, hasCheckedPaDataForApplicantId]);

  const combinedProps: MapPageViewProps = {
    data: data,
    needsDownload,
    handleDownloadParcel,
    isFetching,
    isIAFetching,
    isPAFetching,
    center,
    roadData,
    parcelData,
    form,
    handleModalAction,
    categorySelected,
    dropMarkerMode,
    quickAssessmentMode,
    iaDataSet,
    paDataSet,
    activeModal,
    setActiveModal,
    processingData,
    handleHeaderMenuClick,
    handleHeaderCloseMenu,
    headerMenuAnchorEl,
    headerMenuOptions,
    mapID,
    confirmModalInfo,
    handleOnConfirm,
    confirmLoading,
    parcelsToResetStyle,
    nonParcelMode,
    countyGroupName,
    selectedApplicant,
    isDataLoading,
    applicantsOptions,
    hasFetchedGroups,
    noCoordsPaData,
    setNoCoordsPaData,
    health,
  };

  if (!isFetching && (!data || !data.groupName)) {
    return (
      <ErrorHandler headerTitle='Map'>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '500px',
          }}>
          <ErrorMessage message={'Group ID not found.'} />
        </Box>
      </ErrorHandler>
    );
  }

  return (
    <Box
      className={'layout vertical full-height'}
      style={{ display: location.pathname === '/map' ? 'block' : 'none' }}>
      <MapPage {...combinedProps} />
    </Box>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default memo(MapPageContainer);
