export const CountiesWithParcelData = [
  'Aiken',
  'Beaufort',
  'Berkeley',
  'Calhoun',
  'Charleston',
  'Cherokee',
  'Chester',
  'Chesterfield',
  'Clarendon',
  'Colleton',
  'Darlington',
  'Dillon',
  'Dorchester',
  'Edgefield',
  'Fairfield',
  'Greenville',
  'Greenwood',
  'Hampton',
  'Jasper',
  'Kershaw',
  'Laurens',
  'Lee',
  'Lexington',
  'Marion',
  'Marlboro',
  'McCormick',
  'Newberry',
  'Orangeburg',
  'Pickens',
  'Richland',
  'Saluda',
  'Spartanburg',
  'Sumter',
  'Union',
  'York',
];
