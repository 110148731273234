/* eslint-disable @typescript-eslint/no-explicit-any,no-unused-vars,react-hooks/exhaustive-deps */
// noinspection JSUnresolvedReference,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GeometryObject } from 'geojson';

import { Nullable } from 'interfaces/Nullable.ts';
import { IADetails } from 'interfaces/ia.interface.ts';
import { PAData } from 'interfaces/pa.interface.ts';
import {
  BuildingDamageColors,
  BuildingDamageSvg,
  BuildingDamageTypes,
} from '@/data/LegendValues/BuildingDamage.ts';
import { PACategoryColors } from '@/data';
import { ParcelViewModal } from 'components/fragment/ParcelViewModal/ParcelViewModal.tsx';
import { ParcelData } from 'components/fragment/ParcelViewModal/ParcelViewModal.props.tsx';
import { icon } from 'leaflet';
import 'leaflet.markercluster';
import LocateMe from '../../../assets/icons/map/gps.png';
import AddIcon from '@mui/icons-material/Add';
import { Box, Fab } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BuildingCategorySvg } from '@/data/LegendValues/LegendValues.ts';
import { MarkerViewModal } from 'components/fragment/MarkerViewModal/MarkerViewModal.tsx';
import { updateMapInfo } from 'store/map/map.action.ts';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import { toast } from 'react-toastify';
import { ToastMessage } from 'components/primitive';
import { ToastStatus } from '@/common/enum';
import { teamSelectedSelector } from 'store/team/team.selector.ts';
import { TeamInterface } from 'interfaces/team.interface.ts';
import { selectConnectionHealth } from 'store/connection/connection.selector.ts';
import { cloneDeep } from 'lodash';
import PACategoryValues from '../../../data/PA/PACategoryValues.ts';

declare global {
  interface Window {
    google: any;
  }
}

let map: any = null;
let editHandler: any = null;
let drawnFeatures: any = null;

type MapViewProps = {
  groupName: string;
  center: [number, number];
  zoom?: number;
  data: {
    parcel: any;
    roads: any;
  };
  dropMarkerMode?: boolean;
  quickAssessmentMode?: boolean;
  iaDataSet: Nullable<IADetails[]>;
  paDataSet: Nullable<PAData[]>;
  categorySelected: Nullable<string>;
  mapID: Nullable<string>;
  parcelsToResetStyle: any[];
  nonParcelMode: boolean;
  isDataLoading: boolean;
};

const MapView: React.FC<MapViewProps> = ({
  center,
  groupName,
  data,
  dropMarkerMode,
  quickAssessmentMode,
  iaDataSet,
  paDataSet,
  categorySelected,
  mapID,
  parcelsToResetStyle,
  nonParcelMode,
  isDataLoading,
}) => {
  const isEsriMapAdded = React.useRef(false);
  const L: any = window.L;
  const [parcelLayer, setParcelLayer] = useState<any>(null);
  const [roadLayer, setRoadLayer] = useState<any>(null);
  const webMapLayerRef = useRef<any>(null);
  const parcelLayerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const markerClusters = useRef<any>(null);
  const markersOnMap = useRef<any>({});
  const dropMarkerModeRef = useRef(dropMarkerMode);
  const quickAssessmentModeRef = useRef(quickAssessmentMode);
  const iaDataSetRef = useRef(iaDataSet);
  const paDataSetRef = useRef(paDataSet);
  const categorySelectedRef = useRef(categorySelected);
  const [zoomLevel, setZoomLevel] = useState<number>(17);
  const mapRef: any = React.createRef();
  const { parcel: asParcelData, roads: asRoadData } = data;
  const [parcelView, setParcelView] = useState(false);
  const [parcelData, setParcelData] = useState<ParcelData | null>(null);

  const [markerView, setMarkerView] = useState(false);
  const [markerData, setMarkerData] = useState<IADetails | PAData | null>(null);
  const [userPosition, setUserPosition] = useState<GeolocationPosition | null>(null);
  const [marker, setMarker] = useState<any | null>(null);
  const selectedMarker = useRef<any | null>(null);
  const [showFabButton, setShowFabButton] = useState(false);
  const [newMarkerCoords, setNewMarkerCoords] = useState<any | null>(null);
  const [selectedParcels, setSelectedParcels] = useState<any[]>([]);
  const selectedParcelsRef = useRef<any[]>([]);
  const [teampolygon, setTeampolygon] = useState<any>(null);
  const teamSelected = useAppSelector(teamSelectedSelector);
  const health = useAppSelector(selectConnectionHealth);

  const LocationMarker = icon({
    iconUrl: '/images/locationIcon.svg',
    iconAnchor: [10, 40],
  });
  let circleMarker: any = null;

  const parseTeamSelectionParcels = (team: TeamInterface) => {
    if (!team || !team.parcels || !team.parcels.length) {
      return;
    }
    const temp = JSON.parse(team[`parcels`]);
    if (
      !temp[0] ||
      (!temp[0]['coords'] && !temp[0]['coords'][0] && !temp[0]['coords'][0]['coords'])
    ) {
      return;
    }
    const tempCoords = temp[0]['coords'][0]['coords'];
    const type = temp[0]['coords'][0]['type'];
    const radius = temp[0]['coords'][0]['radius'] || null;
    return { coords: tempCoords, type, radius };
  };

  const centroidLatitudeAndLongitudeFromParcels = (parcels: any) => {
    let LatitudeSum = 0;
    let LongitudeSum = 0;
    // Yes, we could do a for (let parcel of parcels)
    // however, normal for loops is the fastest iterating technique in js
    for (let i = 0; i < parcels.length; i++) {
      LatitudeSum += parcels[i][0];
      LongitudeSum += parcels[i][1];
    }
    const Latitude = LatitudeSum / parcels.length;
    const Longitude = LongitudeSum / parcels.length;
    return [Latitude, Longitude];
  };

  const removeTeamPolygon = () => {
    if (teampolygon) {
      map.removeLayer(teampolygon);
      setTeampolygon(null);
    }
  };

  useEffect(() => {
    if (teamSelected && map && L) {
      const teamData = parseTeamSelectionParcels(teamSelected);
      removeTeamPolygon();
      if (teamData) {
        switch (teamData.type) {
          case 'polygon': {
            const layer = L.polygon(teamData.coords).addTo(map);
            setTeampolygon(layer);
            const centroid = centroidLatitudeAndLongitudeFromParcels(teamData.coords);
            map.panTo(new L.LatLng(centroid[0], centroid[1]));
            break;
          }
          case 'rectangle': {
            const layer = L.rectangle(teamData.coords).addTo(map);
            setTeampolygon(layer);
            const centroid = centroidLatitudeAndLongitudeFromParcels(teamData.coords);
            map.panTo(new L.LatLng(centroid[0], centroid[1]));
            break;
          }
          case 'circle': {
            const layer = L.circle(teamData.coords, teamData.radius).addTo(map);
            setTeampolygon(layer);
            const centroid = centroidLatitudeAndLongitudeFromParcels([teamData.coords]);
            map.panTo(new L.LatLng(centroid[0], centroid[1]));
            break;
          }
        }
      } else {
        toast.info(
          <ToastMessage
            status={ToastStatus.ERROR}
            message={`No parcels found for team ${teamSelected.team_name}`}
          />
        );
      }
    }
  }, [map, teamSelected]);

  const handleAddNewMarker = () => {
    navigate(`/map/${categorySelected}`, {
      state: { markerData: newMarkerCoords },
    });
  };

  const handleAddNewMultiParcel = () => {
    navigate(`/map/${categorySelected}multi`, {
      state: { multiParcelsData: selectedParcels },
    });
    selectedParcels.forEach((parcel) => {
      parcelLayer.resetFeatureStyle(parcel.PIN.toString());
    });
    setSelectedParcels(() => []);
    dispatch(updateMapInfo({ quickAssessmentMode: false }));
  };

  const resetSelectedParcels = () => {
    selectedParcels.forEach((parcel) => {
      parcelLayer.resetFeatureStyle(parcel.PIN.toString());
    });
  };

  // *: Add ESRI MapID
  // function addEsriMapID(newMapId: string) {
  //   // Add a new webMap layer with the new map id
  //   if (map && L && L.esri && L.esri.webMap && newMapId) {
  //     return;
  //     const newWebMap = new L.esri.webMap(newMapId, { map: map });
  //     newWebMap.on('load', function () {
  //       const overlayMaps: any = {};
  //       newWebMap.layers.map(function (l: any) {
  //         overlayMaps[l.title] = l.layer;
  //       });
  //       const layersControl = L.control
  //         .layers({}, overlayMaps, {
  //           position: 'topright',
  //         })
  //         .addTo(newWebMap._map);
  //
  //       // Create and add the overlay
  //       const overlay = L.DomUtil.create('div', 'click-overlay');
  //       overlay.style.position = 'absolute';
  //       overlay.style.top = '0';
  //       overlay.style.left = '0';
  //       overlay.style.width = '100%';
  //       overlay.style.height = '100%';
  //       overlay.style.zIndex = '1000';
  //       overlay.style.backgroundColor = 'rgba(0, 0, 0, 0)'; // Transparent background
  //       overlay.style.display = 'none'; // Initially hidden
  //
  //       document.body.appendChild(overlay);
  //
  //       const layersControlContainer = layersControl.getContainer();
  //       layersControlContainer.addEventListener('click', function (e: any) {
  //         e.stopPropagation();
  //         overlay.style.display = 'block';
  //       });
  //
  //       overlay.addEventListener('click', function (e: any) {
  //         e.stopPropagation();
  //         overlay.style.display = 'none';
  //       });
  //
  //       // Prevent propagation when clicking inside the control
  //       layersControlContainer.addEventListener('mouseover', function () {
  //         overlay.style.display = 'none';
  //       });
  //
  //       map.setView(center, 17);
  //     });
  //   }
  // }
  function addEsriMapID(newMapId: string) {
    return new Promise((resolve, reject) => {
      try {
        // Add a new webMap layer with the new map id
        if (map && L && L.esri && L.esri.webMap && newMapId && !isEsriMapAdded.current) {
          isEsriMapAdded.current = true;
          const newWebMap = L.esri.webMap(newMapId, { map: map });
          webMapLayerRef.current = newWebMap;
          newWebMap.on('metadataLoad', function () {});
          newWebMap.on('load', function () {
            const overlayMaps: any = {};
            newWebMap.layers.map(function (l: any) {
              overlayMaps[l.title] = l.layer;
            });
            L.control
              .layers({}, overlayMaps, {
                position: 'topright',
              })
              .addTo(newWebMap._map);
            setTimeout(() => {
              return resolve(true);
            }, 500);
          });
        }
      } catch (error) {
        console.log('Add ESRI WebMap Layer', error);
        reject(error);
      }
    });
  }

  useEffect(() => {
    quickAssessmentModeRef.current = quickAssessmentMode;
    if (!quickAssessmentMode) {
      resetSelectedParcels();
      setSelectedParcels([]);
      setShowFabButton(false);
    }
  }, [quickAssessmentMode]);

  useEffect(() => {
    selectedParcelsRef.current = selectedParcels;
  }, [selectedParcels]);

  useEffect(() => {
    iaDataSetRef.current = iaDataSet;
  }, [iaDataSet]);

  useEffect(() => {
    paDataSetRef.current = paDataSet;
  }, [paDataSet]);

  useEffect(() => {
    categorySelectedRef.current = categorySelected;
  }, [categorySelected]);

  useEffect(() => {
    parcelLayerRef.current = parcelLayer;
  }, [parcelLayer]);

  useEffect(() => {
    dropMarkerModeRef.current = dropMarkerMode;
  }, [dropMarkerMode]);

  useEffect(() => {
    if (!parcelLayer || !parcelsToResetStyle.length) return;
    parcelsToResetStyle.forEach((parcel: any) => {
      parcelLayer.resetFeatureStyle(parcel.parcel_id.toString());
    });
  }, [parcelsToResetStyle]);

  const handlePolygonClick = (e: any) => {
    const parcelInfo = e.layer.properties;
    setParcelData(parcelInfo);
    const returnFilteredData = () => {
      const filteredData: any = [];
      if (categorySelectedRef.current === 'ia') {
        const filteredIAData: IADetails[] =
          iaDataSetRef.current?.filter((item: IADetails) => item.parcel_id === parcelInfo?.PIN) ??
          [];
        filteredData.push(...filteredIAData);
      } else {
        const filteredPAData: PAData[] =
          paDataSetRef.current?.filter((item: PAData) => item.parcel_id === parcelInfo?.PIN) ?? [];
        filteredData.push(...filteredPAData);
      }
      return filteredData;
    };
    const filteredData: any = returnFilteredData();

    if (!quickAssessmentModeRef.current) {
      if (filteredData.length !== 0) {
        setParcelView(true);
      } else {
        if (selectedParcelsRef.current[0]) {
          //@ts-expect-error/ using parcelLayerRed to access resetFeatureStyle method
          parcelLayerRef.current.resetFeatureStyle(selectedParcelsRef.current[0].PIN.toString());
        }
        setSelectedParcels((prev) => {
          const findIndex = prev.findIndex((parcel) => parcel.PIN === parcelInfo.PIN);
          if (findIndex !== -1) {
            if (parcelLayerRef.current) {
              //@ts-expect-error/ using parcelLayerRed to access resetFeatureStyle method
              parcelLayerRef.current.resetFeatureStyle(prev[findIndex].PIN.toString());
            }
            return prev.filter((parcel) => parcel.PIN !== parcelInfo.PIN);
          } else {
            return [parcelInfo];
          }
        });
      }
    } else {
      if (quickAssessmentModeRef.current && filteredData.length === 0) {
        setSelectedParcels((prev) => {
          const findIndex = prev.findIndex((parcel) => parcel.PIN === parcelInfo.PIN);
          if (findIndex !== -1) {
            if (parcelLayerRef.current) {
              //@ts-expect-error/ using parcelLayerRed to access resetFeatureStyle method
              parcelLayerRef.current.resetFeatureStyle(prev[findIndex].PIN.toString());
            }
            return prev.filter((parcel) => parcel.PIN !== parcelInfo.PIN);
          } else {
            return [...prev, parcelInfo];
          }
        });
      }
    }
  };

  useEffect(() => {
    if (selectedParcels.length && parcelLayer !== null) {
      selectedParcels.forEach((parcel) => {
        parcelLayer.resetFeatureStyle(parcel.PIN.toString());
        parcelLayer.setFeatureStyle(parcel.PIN.toString(), {
          weight: 0.1,
          color: 'red',
          opacity: 0.7,
          fillColor: 'red',
          fill: true,
          radius: 0,
          fillOpacity: 0.6,
        });
      });
      setShowFabButton(true);
    } else {
      setShowFabButton(false);
    }
  }, [selectedParcels, parcelLayer]);

  useEffect(() => {
    if (L) {
      // *: Known issue bug fix Extend the Circle Marker
      // *: [https://stackoverflow.com/questions/47720555/can-not-adjust-radius-in-leaflet-draw]
      // noinspection JSUnusedGlobalSymbols
      L.Edit.Circle = L.Edit.CircleMarker.extend({
        _createResizeMarker: function () {
          const center = this._shape.getLatLng(),
            resizemarkerPoint = this._getResizeMarkerPoint(center);

          this._resizeMarkers = [];
          this._resizeMarkers.push(this._createMarker(resizemarkerPoint, this.options.resizeIcon));
        },

        _getResizeMarkerPoint: function (latlng: any) {
          const delta = this._shape._radius * Math.cos(Math.PI / 4),
            point = this._map.project(latlng);
          return this._map.unproject([point.x + delta, point.y - delta]);
        },

        _resize: function (latlng: any) {
          const moveLatLng = this._moveMarker.getLatLng();
          let radius;

          if (L.GeometryUtil.isVersion07x()) {
            radius = moveLatLng.distanceTo(latlng);
          } else {
            radius = this._map.distance(moveLatLng, latlng);
          }

          // **** This fixes the circle resizing ****
          this._shape.setRadius(radius);

          this._map.fire(L.Draw.Event.EDITRESIZE, { layer: this._shape });
        },
      });
    }
  }, [L]);

  useEffect(() => {
    if (map && L && !L.Control.LocateButton) {
      L.Control.LocateButton = L.Control.extend({
        onAdd: () => {
          if (!document.querySelector('#dmaLocateButton')) {
            const btn = L.DomUtil.create('div');
            btn.id = 'dmaLocateButton';
            btn.class = 'layout horizontal center center-justified';
            btn.style.padding = '4px';
            btn.style.backgroundColor = 'white';
            btn.style.width = '34px';
            btn.style.height = '34px';
            btn.style.cursor = 'pointer';
            btn.style.border = '2px solid rgba(0,0,0,0.2)';
            btn.style.borderRadius = '4px';
            btn.style.boxSizing = 'border-box !important';
            const img = document.createElement('img');
            img.style.marginLeft = 'auto';
            img.style.marginRight = 'auto';
            img.style.display = 'block';
            img.style.height = '20px';
            img.style.width = '20px';
            img.src = `${LocateMe}`;
            btn.appendChild(img);
            L.DomEvent.on(btn, 'mousedown dblclick', L.DomEvent.stopPropagation)
              .on(btn, 'click', L.DomEvent.stop)
              .on(btn, 'click', (clickEvent: Event) => {
                clickEvent.preventDefault();
                clickEvent.stopPropagation();

                const options = {
                  enableHighAccuracy: true,
                };
                navigator.geolocation.getCurrentPosition(
                  (position: GeolocationPosition) => {
                    setUserPosition(position);
                  },
                  (err) => {
                    console.error('Get current position error:  ', err);
                  },
                  options
                );
              });
            return btn;
          }
        },
      });
      L.control.LocateButton = (opts: any) => {
        return new L.Control.LocateButton(opts);
      };
      L.control.LocateButton({ position: 'bottomleft' }).addTo(map);
    }
  }, [map, L]);

  useEffect(() => {
    if (map && L && window.google && !L.Control.GPlaceAutocomplete) {
      L.Control.GPlaceAutocomplete = L.Control.extend({
        options: {
          position: 'topright',
          prepend: true,
          collapsed_mode: false,
          placeholder: null,
          autocomplete_options: {},
        },

        collapsedModeIsExpanded: true,

        autocomplete: null,
        icon: null,
        searchBox: null,

        initialize: function (options: any) {
          if (options) {
            L.Util.setOptions(this, options);
          }
          if (!this.options.callback) {
            this.options.callback = this.onLocationComplete;
          }
          this._buildContainer();
        },

        _buildContainer: function () {
          if (!window.google) return;

          // build structure
          this.container = L.DomUtil.create('div', 'leaflet-gac-container leaflet-bar');
          const searchWrapper = L.DomUtil.create('div', 'leaflet-gac-wrapper');
          this.searchBox = L.DomUtil.create('input', 'leaflet-gac-control');
          this.autocomplete = new window.google.maps.places.Autocomplete(
            this.searchBox,
            this.options.autocomplete_options
          );
          this.searchBox.setAttribute('type', 'search');

          const onSearch = () => {
            if (this.options.clear) {
              this.options.clear();
            }
          };

          L.DomEvent.addListener(this.searchBox, 'search', onSearch, this);

          if (this.options.placeholder) {
            this.searchBox.setAttribute('placeholder', this.options.placeholder);
          }

          // if collapse mode set - create icon and register events
          if (this.options.collapsed_mode) {
            this.collapsedModeIsExpanded = false;

            this.icon = L.DomUtil.create('div', 'leaflet-gac-search-btn');
            L.DomEvent.on(this.icon, 'click', this._showSearchBar, this);

            this.icon.appendChild(L.DomUtil.create('div', 'leaflet-gac-search-icon'));

            searchWrapper.appendChild(this.icon);
            L.DomUtil.addClass(this.searchBox, 'leaflet-gac-hidden');
          }

          searchWrapper.appendChild(this.searchBox);
          // create and bind autocomplete
          this.container.appendChild(searchWrapper);
        },

        //***
        // Collapse mode callbacks
        //***

        _showSearchBar: function () {
          this._toggleSearch(!this.collapsedModeIsExpanded);
        },

        _hideSearchBar: function () {
          // if an element is expanded, we need to change the expanded flag and call collapse handler
          if (this.collapsedModeIsExpanded) {
            this._toggleSearch(false);
          }
        },

        _toggleSearch: function (shouldDisplaySearch: any) {
          if (shouldDisplaySearch) {
            L.DomUtil.removeClass(this.searchBox, 'leaflet-gac-hidden');
            // L.DomUtil.addClass(this.icon, "leaflet-gac-hidden");
            this.searchBox.focus();
          } else {
            L.DomUtil.addClass(this.searchBox, 'leaflet-gac-hidden');
            L.DomUtil.removeClass(this.icon, 'leaflet-gac-hidden');
          }
          this.collapsedModeIsExpanded = shouldDisplaySearch;
        },

        //***
        // Default success callback
        //***

        onLocationComplete: function (place: any, map: any) {
          // default callback
          if (!place.geometry) {
            // alert("Location not found");
            return;
          }
          map.panTo([place.geometry.location.lat(), place.geometry.location.lng()]);
        },

        onAdd: function () {
          // stop propagation of click events
          // L.DomEvent.addListener(this.container, 'click', L.DomEvent.stop);
          // L.DomEvent.disableClickPropagation(this.container);
          if (this.options.collapsed_mode) {
            // if collapse mode - register handler
            // this._map.on('dragstart click', this._hideSearchBar, this);
          }
          return this.container;
        },

        addTo: function (map: any) {
          if (!window.google) return;
          this._map = map;

          const container = (this._container = this.onAdd(map)),
            pos = this.options.position,
            corner = map._controlCorners[pos];

          L.DomUtil.addClass(container, 'leaflet-control');
          if (this.options.prepend) {
            corner.insertBefore(container, corner.firstChild);
          } else {
            corner.appendChild(container);
          }

          const callback = this.options.callback;
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this = this;
          window.google.maps.event.addListener(this.autocomplete, 'place_changed', function () {
            callback(_this.autocomplete.getPlace(), map);
          });

          return this;
        },
      });
      new L.Control.GPlaceAutocomplete({
        collapsed_mode: true,
        position: 'topleft',
        clear: function () {
          if (circleMarker) {
            map.removeLayer(circleMarker);
            circleMarker = null;
          }
        },
        callback: function (place: any) {
          if (place && place.geometry && place.geometry.location) {
            const loc = place.geometry.location;
            const latlng = [loc.lat(), loc.lng()];
            circleMarker = L.marker(latlng, {
              icon: L.divIcon({
                className: 'circle',
                iconSize: [32, 32],
              }),
              title: place.formatted_address,
            }).addTo(map);
            map.setView([loc.lat(), loc.lng()], map.getZoom());
          }
        },
      }).addTo(map);
    }
  }, [map, L, window.google]);

  useEffect(() => {
    if (userPosition) {
      const { latitude, longitude } = userPosition.coords;
      if (marker) {
        map.removeLayer(marker);
      }
      const test = L.marker([latitude, longitude], { icon: LocationMarker }).addTo(map);
      setMarker(test);
      map.setView([latitude, longitude], map.getZoom());
    }
  }, [userPosition]);

  useEffect(() => {
    if (map && L && !L.Control.Pan) {
      L.Control.Pan = L.Control.extend({
        options: {
          position: 'bottomright',
          panOffset: 500,
        },

        onAdd: function (map: any) {
          let controlContainer;
          const className = 'leaflet-control-pan',
            container = L.DomUtil.create('div', className),
            off = this.options.panOffset;

          this._panButton('Up', className + '-up', container, map, new L.Point(0, -off));
          this._panButton('Left', className + '-left', container, map, new L.Point(-off, 0));
          this._panButton('Right', className + '-right', container, map, new L.Point(off, 0));
          this._panButton('Down', className + '-down', container, map, new L.Point(0, off));

          // Add pan control class to the control container
          if (this.options.position === 'topleft') {
            controlContainer = L.DomUtil.get(map._controlCorners.topleft);
          } else if (this.options.position === 'topright') {
            controlContainer = L.DomUtil.get(map._controlCorners.topright);
          } else if (this.options.position === 'bottomleft') {
            controlContainer = L.DomUtil.get(map._controlCorners.bottomleft);
          } else {
            controlContainer = L.DomUtil.get(map._controlCorners.bottomright);
          }
          if (!L.DomUtil.hasClass(controlContainer, 'has-leaflet-pan-control')) {
            L.DomUtil.addClass(controlContainer, 'has-leaflet-pan-control');
          }

          return container;
        },

        onRemove: function (map: any) {
          // Remove pan control class to the control container
          const controlContainer = L.DomUtil.get(map._controlCorners.topleft);
          if (L.DomUtil.hasClass(controlContainer, 'has-leaflet-pan-control')) {
            L.DomUtil.removeClass(controlContainer, 'has-leaflet-pan-control');
          }
        },

        _panButton: function (
          title: string,
          className: string,
          container: any,
          map: any,
          offset: any
        ) {
          const wrapper = L.DomUtil.create('div', className + '-wrap', container);
          const link = L.DomUtil.create('a', className, wrapper);
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'click', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.preventDefault)
            .on(
              link,
              'click',
              function () {
                map.panBy(offset);
              },
              map
            )
            .on(link, 'dblclick', L.DomEvent.stopPropagation);

          return link;
        },
      });

      L.control.pan = (opts: any) => {
        return new L.Control.Pan(opts);
      };
      L.control.pan({ position: 'bottomright' }).addTo(map);
    }
  }, [map, L]);

  useEffect(() => {
    if (!parcelLayer) return;
    resetParcelLayerStyle();
    if (zoomLevel > 16) {
      showSavedPolygons();
    }
  }, [iaDataSet, parcelLayer, paDataSet, categorySelected]);

  const onClickFunc = useCallback((e: any) => {
    if (selectedMarker.current) {
      map.removeLayer(selectedMarker.current);
    }
    selectedMarker.current = L.marker(e.latlng, { icon: LocationMarker }).addTo(map);
    setNewMarkerCoords(e.latlng);
    if (!showFabButton) setShowFabButton(true);
  }, []);

  useEffect(() => {
    if (asRoadData && L && map) {
      if (health && roadLayer) {
        if (map.hasLayer(roadLayer)) {
          map.removeLayer(roadLayer);
        }
        setRoadLayer(null);
      } else if (!health && !roadLayer) {
        loadRoadData();
      }
    }
  }, [asRoadData, map, L, health, roadLayer]);

  useEffect(() => {
    if (L && map) {
      if (dropMarkerMode) {
        if (selectedParcels.length) {
          setSelectedParcels([]);
        }
        if (parcelLayerRef.current) {
          map.removeLayer(parcelLayerRef.current);
          setParcelLayer(null);
          parcelLayerRef.current = null;
        }
        if (map.getPane('layerForeground')) {
          map.getPane('layerForeground').style.zIndex = 1;
        }
        map.on('click', onClickFunc);
        L.DomUtil.addClass(map._container, 'crosshair-cursor-enabled');
      } else {
        map.off('click', onClickFunc);
        setShowFabButton(false);
        setNewMarkerCoords(null);
        if (selectedMarker.current) {
          map.removeLayer(selectedMarker.current);
        }
        if (map.getZoom() > 16) {
          drawParcelGrid();
        }
        L.DomUtil.removeClass(map._container, 'crosshair-cursor-enabled');
        if (map.getPane('layerForeground')) {
          map.getPane('layerForeground').style.zIndex = 400;
        }
      }
    }
  }, [map, L, dropMarkerMode]);

  useEffect(() => {
    if (!L) return;
    const node = mapRef.current as HTMLElement;
    const mapObj: any = {
      center: center,
      zoomControl: false,
      zoom: 17,
      maxZoom: 20,
    };
    const mapboxUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    const mapboxAttribution = `<a href="https://osm.org/copyright">OpenStreetMap</a> contributors`;
    if (L && L.StreetLabels) {
      mapObj['renderer'] = new L.StreetLabels({
        collisionFlg: true,
        propertyName: 'FULLNAME',
        showLabelIf: function () {
          return true;
        },
        fontStyle: {
          dynamicFontSize: false,
          fontSize: 10,
          fontSizeUnit: 'px',
          lineWidth: 4.0,
          fillStyle: 'black',
          strokeStyle: 'white',
        },
      });
    }
    const grayscale = L.tileLayer(mapboxUrl, {
      id: '<a href="https://mapbox.com">MapID</a>',
      // tileSize: 512,
      // zoomOffset: -1,
      attribution: mapboxAttribution,
    });
    const streets = L.tileLayer(mapboxUrl, {
      id: '<a href="https://mapbox.com">MapID</a>',
      // tileSize: 512,
      // zoomOffset: -1,
      attribution: mapboxAttribution,
    });
    mapObj['layers'] = [grayscale, streets]; // This adds layers to the basemap
    mapObj['attribution'] = mapboxAttribution;
    mapObj['id'] = '<a href="https://mapbox.com">MapID</a>';

    // *: Initialize the Map only once
    if (node.children.length === 0) {
      map = L.map(node, mapObj);
      drawnFeatures = new L.FeatureGroup();
      map.addLayer(drawnFeatures);

      const editToolbar = new L.EditToolbar({
        featureGroup: drawnFeatures,
      });
      editHandler = editToolbar.getModeHandlers()[0].handler;
      editHandler._map = map;
      L.control
        .zoom({
          position: 'bottomleft',
        })
        .addTo(map);

      if (
        map &&
        mapID &&
        !isDataLoading &&
        !isEsriMapAdded.current &&
        asParcelData &&
        !webMapLayerRef.current
      ) {
        addEsriMapID(mapID).then(() => {
          console.log('ESRI MAP ADDED');
        });
      }
      drawParcelGrid();
    }
  }, [mapRef]);

  useEffect(() => {
    if (map) {
      map.on('zoomend', function () {
        setZoomLevel(map.getZoom());
        if (map.getZoom() < 17) {
          if (parcelLayerRef.current) {
            map.removeLayer(parcelLayerRef.current);
            setParcelLayer(null);
            parcelLayerRef.current = null;
          }
        } else {
          if (!parcelLayerRef.current && !dropMarkerModeRef.current) {
            drawParcelGrid();
          }
        }
        // Explicitly call the onResize function to fix the memory leak issue with vector grid.
        map._onResize();
      });
    }
  }, [map]);

  const addLongLatToParcelMarkers = (parcelMarkersDataSet: any) => {
    parcelMarkersDataSet.forEach((parcel: any) => {
      if (parcel.parcel_id && parcel?.pvParcelGeometryObject) {
        // Don't parse this because of browser memory constraints
        const idx = parcel.pvParcelGeometryObject.indexOf('-');
        let endIdx = -1;
        if (idx >= 0) {
          endIdx = parcel.pvParcelGeometryObject.indexOf(']', endIdx);
        }
        if (idx !== -1 && endIdx !== -1) {
          const str = parcel.pvParcelGeometryObject.substring(idx, endIdx);
          const split = str.split(',');
          const lat_lon: [number, number] = [parseFloat(split[1]), parseFloat(split[0])];
          if (lat_lon) {
            parcel.latitude = lat_lon[0];
            parcel.longitude = lat_lon[1];
          }
        }
      } else if (parcel.parcel_id && parcel?.latitude && parcel?.longitude) {
        const lat_lon: [number, number] = [parcel.latitude, parcel.longitude];
        if (lat_lon) {
          parcel.latitude = lat_lon[0];
          parcel.longitude = lat_lon[1];
        }
      }
    });
    return parcelMarkersDataSet;
  };

  useEffect(() => {
    if (
      map &&
      // iaDataSet &&
      // iaDataSet.length > 0 &&
      // markerClusters.current &&
      categorySelected &&
      categorySelected === 'ia'
    ) {
      const addMarkers: IADetails[] = [];
      if (markerClusters.current) {
        markerClusters.current.clearLayers();
      }
      if (!iaDataSet) return;
      const markersDataSet = iaDataSet
        .filter((iaData) => iaData.parcel_id === null)
        .filter((iaData) => iaData.dma_category === 'ia')
        .filter((iaData) => iaData.latitude && iaData.longitude);
      // const markersDataSetIds = markersDataSet.map(
      //   (iaData) =>
      //     iaData.latitude!.toString() +
      //     iaData.longitude!.toString() +
      //     iaData.dma_category +
      //     iaData.created_at
      // );
      Object.keys(markersOnMap.current).forEach((key) => {
        const marker = markersOnMap.current[key];
        // if (!markersDataSetIds.includes(key)) {
        delete markersOnMap.current[key];
        markerClusters.current.removeLayer(marker);
        // }
      });

      markersDataSet.forEach((iaData) => {
        // const markerId =
        //   iaData.latitude!.toString() +
        //   iaData.longitude!.toString() +
        //   iaData.dma_category +
        //   iaData.created_at;
        // if (!markersOnMap.current[markerId]) {
        addMarkers.push(iaData);
        // }
      });
      if (zoomLevel < 17) {
        let parcelMarkersDataSet: IADetails[] = cloneDeep(iaDataSet);

        parcelMarkersDataSet = parcelMarkersDataSet
          .filter((iaData) => iaData.parcel_id !== null)
          .filter((iaData) => iaData.dma_category === 'ia');
        addLongLatToParcelMarkers(parcelMarkersDataSet);
        const parcelMarkersDataSetIds = parcelMarkersDataSet.map(
          (iaData) =>
            (iaData.latitude && iaData.latitude.toString() ? iaData.latitude.toString() : '0.0') +
            (iaData.longitude && iaData.longitude.toString()
              ? iaData.longitude.toString()
              : '0.0') +
            iaData.dma_category +
            iaData.created_at
        );

        Object.keys(markersOnMap.current).forEach((key) => {
          const marker = markersOnMap.current[key];
          if (!parcelMarkersDataSetIds.includes(key)) {
            delete markersOnMap.current[key];
            markerClusters.current.removeLayer(marker);
          }
        });

        parcelMarkersDataSet.forEach((iaData) => {
          if (
            iaData.latitude &&
            iaData.latitude.toString() &&
            iaData.longitude &&
            iaData.longitude.toString()
          ) {
            const markerId =
              iaData.latitude.toString() +
              iaData.longitude.toString() +
              iaData.dma_category +
              iaData.created_at;
            if (!markersOnMap.current[markerId]) {
              addMarkers.push(iaData);
            }
          }
        });
      }

      if (addMarkers.length > 0) {
        addMarkers.forEach((iaData) => {
          if (
            iaData.latitude &&
            iaData.latitude.toString() &&
            iaData.longitude &&
            iaData.longitude.toString()
          ) {
            const buildingDamage = iaData.building_damage
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
            const mapColor = BuildingDamageSvg[buildingDamage as BuildingDamageTypes];
            const markerId =
              iaData.latitude.toString() +
              iaData.longitude.toString() +
              iaData.dma_category +
              iaData.created_at;

            const marker = L.marker([iaData.latitude, iaData.longitude], {
              entryID: markerId,
              icon: icon({
                iconUrl: mapColor ?? BuildingDamageSvg[BuildingDamageTypes.Empty],
                iconSize: [32, 32],
              }),
              zIndexOffset: 9000,
            });

            if (markerClusters.current) {
              markerClusters.current.addLayer(marker);
            }

            marker.on('click', () => {
              setMarkerData(iaData);
              setMarkerView(true);
            });

            markersOnMap.current[markerId] = marker;
          }
        });
      }
    }
  }, [map, iaDataSet, categorySelected, zoomLevel]);

  useEffect(() => {
    if (
      map &&
      // paDataSet &&
      // paDataSet.length > 0 &&
      // markerClusters.current &&
      categorySelected &&
      categorySelected === 'pa'
    ) {
      const addMarkers: PAData[] = [];
      if (markerClusters.current) {
        markerClusters.current.clearLayers();
      }
      if (!paDataSet) return;
      const markersDataSet = paDataSet
        .filter((paData) => paData.parcel_id === null)
        .filter((paData) => paData.dma_category === 'pa')
        .filter((paData) => paData.latitude && paData.longitude);

      // const markersDataSetIds = markersDataSet.map(
      //   (paData) =>
      //     paData.latitude?.toString() +
      //     paData.longitude?.toString() +
      //     paData.dma_category +
      //     paData.created_at
      // );

      Object.keys(markersOnMap.current).forEach((key) => {
        const marker = markersOnMap.current[key];
        // if (!markersDataSetIds.includes(key)) {
        delete markersOnMap.current[key];
        markerClusters.current.removeLayer(marker);
        // }
      });

      markersDataSet.forEach((paData) => {
        // const markerId =
        //   paData.latitude?.toString() +
        //   paData.longitude?.toString() +
        //   paData.dma_category +
        //   paData.created_at;
        // if (!markersOnMap.current[markerId]) {
        addMarkers.push(paData);
        // }
      });

      if (zoomLevel < 17) {
        let parcelMarkersDataSet: PAData[] = cloneDeep(paDataSet);

        parcelMarkersDataSet = parcelMarkersDataSet
          .filter((iaData) => iaData.parcel_id !== null)
          .filter((iaData) => iaData.dma_category === 'pa');

        addLongLatToParcelMarkers(parcelMarkersDataSet);

        const parcelMarkersDataSetIds = parcelMarkersDataSet.map(
          (paData) =>
            (paData.latitude && paData.latitude.toString() ? paData.latitude.toString() : '0.0') +
            (paData.longitude && paData.longitude.toString()
              ? paData.longitude.toString()
              : '0.0') +
            paData.dma_category +
            paData.created_at
        );

        Object.keys(markersOnMap.current).forEach((key) => {
          const marker = markersOnMap.current[key];
          if (!parcelMarkersDataSetIds.includes(key)) {
            delete markersOnMap.current[key];
            markerClusters.current.removeLayer(marker);
          }
        });

        parcelMarkersDataSet.forEach((paData) => {
          if (
            paData.latitude &&
            paData.latitude.toString() &&
            paData.longitude &&
            paData.longitude.toString()
          ) {
            const markerId =
              paData.latitude.toString() +
              paData.longitude.toString() +
              paData.dma_category +
              paData.created_at;
            if (!markersOnMap.current[markerId]) {
              addMarkers.push(paData);
            }
          }
        });
      }

      if (addMarkers.length > 0) {
        addMarkers.forEach((paData) => {
          if (
            paData.latitude &&
            paData.latitude.toString() &&
            paData.longitude &&
            paData.longitude.toString()
          ) {
            const mapColor = BuildingCategorySvg[paData.category];
            const markerId =
              paData.latitude.toString() +
              paData.longitude.toString() +
              paData.dma_category +
              paData.created_at;

            const marker = L.marker([paData.latitude, paData.longitude], {
              entryID: markerId,
              icon: icon({
                iconUrl: mapColor ?? BuildingCategorySvg[PACategoryValues.DebrisRemoval.value],
                iconSize: [32, 32],
              }),
              zIndexOffset: 9000,
            });

            markersOnMap.current[markerId] = marker;
            if (markerClusters.current) {
              markerClusters.current.addLayer(marker);
            }

            marker.on('click', () => {
              setMarkerData(paData);
              setMarkerView(true);
            });
          }
        });
      }
    }
  }, [map, paDataSet, categorySelected, zoomLevel]);

  function loadRoadData() {
    // const map = mapRef.current;
    // *: Load Roads GeoJSON
    // *: ArrayBuffer to text.
    const bufView = new Uint8Array(asRoadData);
    const length = bufView.length;
    let decoded = '';
    let addition = Math.pow(2, 16) - 1;
    for (let i = 0; i < length; i += addition) {
      if (i + addition > length) {
        addition = length - i;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      decoded += String.fromCharCode.apply(null, bufView.subarray(i, i + addition));
    }
    const decodedRoads: GeometryObject = JSON.parse(decoded);
    if (decodedRoads) {
      const roadLayer = L.geoJSON(decodedRoads, {
        style: {
          color: '#f2b648',
          weight: 3,
          opacity: 1,
        },
      }).addTo(map);
      setRoadLayer(roadLayer);

      setTimeout(() => {
        map.invalidateSize();
      }, 2500);
    }
  }

  function drawParcelGrid() {
    if (dropMarkerModeRef.current) {
      return;
    }
    // *: Load Parcels MBTiles
    map.createPane('layerForeground');
    map.getPane('layerForeground').style.zIndex = '400';
    map.createPane('markerClusterForeground');
    map.getPane('markerClusterForeground').style.zIndex = '999';

    if (!markerClusters.current) {
      markerClusters.current = L.markerClusterGroup({
        clusterPane: 'markerClusterForeground',
      });
      map.addLayer(markerClusters.current);
    }

    const layerObj = {
      pane: 'layerForeground',
      vectorTileLayerStyles: {},
      interactive: true,
      getFeatureId: function (f: any) {
        return f.properties.PIN;
      },
      rendererFactory: L.svg.tile,
      token: '',
      detectRetina: true,
      onEachFeature: (function () {
        return function (feature: any, _featureLayer: any, _vtLayer: any, tileCoords: any) {
          if (feature.properties.latlngCoords === undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const tileCoordsY = this._globalTileRange.max.y - tileCoords.y;
            feature.properties.latlngCoords = feature.toGeoJSON(
              tileCoords.x,
              tileCoordsY,
              tileCoords.z
            ).geometry.coordinates;
          }
        };
      })(),
    };
    const groupNames = groupName.toLowerCase();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    layerObj.vectorTileLayerStyles[groupNames] = {
      fillColor: 'transparent',
      fill: true,
      color: 'red',
      opacity: 0.9,
      weight: 0.1,
    };

    // Check for Vector Grid
    if (L && L.vectorGrid && parcelLayer === null && !nonParcelMode) {
      const newParcelLayer = L.vectorGrid.mbtiles(asParcelData, layerObj).addTo(map);
      setParcelLayer(newParcelLayer);
      parcelLayerRef.current = newParcelLayer;
      newParcelLayer.on('click', handlePolygonClick);

      setTimeout(() => {
        map.invalidateSize();
      }, 500);
    }
  }

  const getLatestParcels = (parcelArray: IADetails[]) => {
    const latestParcels = parcelArray.reduce((acc: { [key: string]: any }, current) => {
      if (current.parcel_id !== null) {
        const existingParcel = acc[current.parcel_id];
        if (!existingParcel || existingParcel.created_at < current.created_at) {
          acc[current.parcel_id] = current;
        }
      }
      return acc;
    }, {});

    return Object.values(latestParcels);
  };

  const showSavedPolygons = () => {
    if (dropMarkerMode) {
      return;
    }

    if (iaDataSet !== null && categorySelected === 'ia') {
      const latestParcels: IADetails[] = getLatestParcels(iaDataSet);

      latestParcels.forEach((parcel) => {
        // if (!parcel.pvParcelGeometryObject) return;
        if (parcel.parcel_id) {
          parcelLayer.resetFeatureStyle(parcel.parcel_id.toString());
          const mapColor = BuildingDamageColors[parcel.building_damage];
          if (mapColor) {
            parcelLayer.setFeatureStyle(parcel.parcel_id.toString(), {
              weight: 0.1,
              color: mapColor,
              opacity: 0.7,
              fillColor: mapColor,
              fill: true,
              radius: 0,
              fillOpacity: 0.6,
            });
          }
        }
      });
    }

    if (paDataSet !== null && categorySelected === 'pa') {
      paDataSet.forEach((parcel) => {
        if (parcel.parcel_id) {
          parcelLayer.resetFeatureStyle(parcel.parcel_id.toString());
          const mapColor = PACategoryColors[parcel.category];
          if (mapColor) {
            parcelLayer.setFeatureStyle(parcel.parcel_id.toString(), {
              weight: 0.1,
              color: mapColor,
              opacity: 0.7,
              fillColor: mapColor,
              fill: true,
              radius: 0,
              fillOpacity: 0.6,
            });
          }
        }
      });
    }
  };

  const resetParcelLayerStyle = () => {
    if (iaDataSet !== null) {
      iaDataSet.forEach((parcel) => {
        if (parcel.parcel_id) {
          parcelLayer.resetFeatureStyle(parcel.parcel_id.toString());
        }
      });
    }
    if (paDataSet !== null) {
      paDataSet.forEach((parcel) => {
        if (parcel.parcel_id) {
          parcelLayer.resetFeatureStyle(parcel.parcel_id.toString());
        }
      });
    }
  };

  // const controlPanPosition = (val: boolean) => {
  //   if (
  //     document.getElementsByClassName('leaflet-control-pan') &&
  //     document.getElementsByClassName('leaflet-control-pan').length
  //   ) {
  //     const elem = document.getElementsByClassName('leaflet-control-pan')[0] as HTMLElement;
  //     elem.style.marginBottom = val ? '88px' : 'unset';
  //   }
  //   return val;
  // };

  const handleAddParcel = () => {
    if (parcelData) {
      navigate(`/map/${categorySelected}/${parcelData.PIN}`, {
        state: { parcelData: parcelData },
      });
      selectedParcels.forEach((parcel) => {
        parcelLayer.resetFeatureStyle(parcel.PIN.toString());
      });
      setShowFabButton(false);
      setSelectedParcels([]);
    }
  };

  return (
    <>
      <div
        id='map'
        ref={mapRef}
        style={{ height: '100%', width: '100%', position: 'absolute' }}></div>
      {parcelView && parcelData && (
        <ParcelViewModal
          onClose={setParcelView}
          optionData={parcelData}
          headerTitle={'Assessments List'}
          categorySelected={categorySelected}
        />
      )}
      {markerView && markerData && (
        <MarkerViewModal onClose={setMarkerView} optionData={markerData} />
      )}
      {showFabButton && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 164,
            right: 32,
            zIndex: 1002, // Ensure the button is on top of other elements
          }}>
          <Fab
            sx={{
              backgroundColor: '#ff4081',
              '&:hover': {
                backgroundColor: '#ff4081',
              },
            }}
            variant='circular'
            size='large'
            onClick={
              dropMarkerMode
                ? handleAddNewMarker
                : quickAssessmentMode
                  ? handleAddNewMultiParcel
                  : handleAddParcel
            }>
            <AddIcon className='icon' />
          </Fab>
        </Box>
      )}
    </>
  );
};

export default MapView;
