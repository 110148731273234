import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';

export const getAccountsInfo = (endURL: string): IEndPoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: `/api/accounts${endURL}`,
  };
};

export const getUserDMASettings = (accountID: number): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/api/damageassessment_ms' + '?pvAccountID=' + accountID,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};

export const getPalmettoGroups = (parentGroupID?: number): IEndPoint => {
  const url = parentGroupID ? `/api/groups/${parentGroupID}` : '/api/groups';
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url,
  };
};
export const getPalmettoGroupsFilter = (filter?: string | null): IEndPoint => {
  const url = filter ? `/api/groups?filter=${filter}` : '/api/groups';
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url,
  };
};
