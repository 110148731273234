/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import { selectAuthToken, selectAuthTokenVerification } from '@/store/authToken/authToken.selector';
import { useWebSocket } from '@/hooks/useWebSocket.ts';
import { WSRoomNames } from '@/common/enum';
import { updatePouchDBItem, ScriptsLoader } from '@/utilities';

import { fetchAllGroups, fetchUserDMASettings } from 'store/accountsInfo/accountsInfo.thunk.ts';
import {
  fetchIncidentForParcelSync,
  fetchJPDAIncidentSetting,
  fetchUsersSavedIncidents,
} from 'store/incidents/incidents.thunk.ts';
import {
  fetchApplicants,
  fetchSelectedApplicantSettingsPWA,
} from 'store/applicants/applicants.thunk.ts';
import { Loading } from 'components/primitive';
import { setIsInitApiLoading, setWSConnection } from 'store/connection/connection.actions.ts';
import {
  selectConnectionHealth,
  selectIsInitApiLoading,
  selectWsConnection,
} from 'store/connection/connection.selector.ts';
import { parcelSyncIncidentIDSelector } from 'store/incidents/incidents.selector.ts';
import { fetchAllTeams } from 'store/teams/teams.thunk.ts';
import {
  accountGroupIDSelector,
  selectedGroupNameForParcels,
} from 'store/accountsInfo/accountsInfo.selector.ts';
import { fetchMapLayerID } from 'store/map/map.thunk.ts';
import {
  setAllGroups,
  setLoadingState,
  setNonParcelMode,
} from 'store/accountsInfo/accountsInfo.actions.ts';
import { PalmettoGroups } from 'interfaces/UserAccountInfo.interface.ts';
import axios from 'axios';

const LoadingContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const auth = useAppSelector(selectAuthTokenVerification);
  const token = useAppSelector(selectAuthToken);
  const isApiLoading = useAppSelector(selectIsInitApiLoading);
  const health = useAppSelector(selectConnectionHealth);
  const wsConnection = useAppSelector(selectWsConnection);
  const selectedIncidentForParcelSync = useAppSelector(parcelSyncIncidentIDSelector);
  const selectedGroupID = useAppSelector(accountGroupIDSelector);
  const groupNameForParcels = useAppSelector(selectedGroupNameForParcels);
  const [dataFetched, setDataFetched] = useState(false);
  const { connect, joinRoom } = useWebSocket();
  ScriptsLoader();

  type CallbackFunction = () => Promise<string>;
  const fetchDataAndHandleErrors = async (callbackFn: CallbackFunction) => {
    try {
      if (health) {
        dispatch(setIsInitApiLoading(true));
        await callbackFn();
        dispatch(setIsInitApiLoading(false));
      } else {
        dispatch(setIsInitApiLoading(false));
        return navigate('/map');
      }
    } catch (error) {
      dispatch(setIsInitApiLoading(false));
      console.error('Error occurred while fetching data', error);
    }
  };

  useEffect(() => {
    if (auth === null || !token || isApiLoading) return;
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        const GROUPS_ARRAY_AWS: PalmettoGroups[] = await axios
          .get('https://palmetto-wab.s3.amazonaws.com/s3db/groups.json')
          .then((resp) => resp.data ?? []);
        if (GROUPS_ARRAY_AWS.length) {
          const filteredGroup = GROUPS_ARRAY_AWS.filter(
            (group) => Number(group.pvGroupID) >= 1 && Number(group.pvGroupID) <= 48
          ).sort((a, b) => a.pvGroupName.localeCompare(b.pvGroupName));
          dispatch(setAllGroups(filteredGroup));
        }

        await dispatch(fetchUserDMASettings(controller))
          .unwrap()
          .then(async () => {
            await dispatch(fetchIncidentForParcelSync(controller)).unwrap();
            await dispatch(fetchUsersSavedIncidents()).unwrap();
            await dispatch(fetchAllGroups(controller)).unwrap();
            // await dispatch(fetchSelectedIncidentSetting(controller)).unwrap();
            await dispatch(fetchJPDAIncidentSetting(controller)).unwrap();
            await dispatch(fetchSelectedApplicantSettingsPWA()).unwrap();
            await dispatch(fetchApplicants()).unwrap();
            await dispatch(fetchMapLayerID());
          });

        return 'DONE';
      } catch (error) {
        console.error('Error in fetchData:', error);
        throw error;
      }
    };

    fetchDataAndHandleErrors(fetchData).then(() => {
      setDataFetched(true);
    });

    return () => {
      dispatch(setLoadingState(false));
    };
  }, [token, auth, isApiLoading]);

  useEffect(() => {
    if (auth === null || !token || !selectedIncidentForParcelSync || !dataFetched) return;
    type ConnectWsParams = {
      userId: number;
      groupId: number;
      isSuperAdmin: boolean;
      accessToken: string;
    };

    const connectWs = async ({ userId, groupId, isSuperAdmin, accessToken }: ConnectWsParams) => {
      await connect(userId, groupId, isSuperAdmin, accessToken);
    };
    if (!wsConnection) {
      connectWs({
        userId: auth.id,
        groupId: auth.pvActiveGroupID,
        isSuperAdmin: false,
        accessToken: auth.accessToken || '',
      }).then(() => {
        dispatch(setWSConnection(true));

        joinRoom(
          WSRoomNames.DMA_APP_IA_ROOM_NAME_BASE + selectedIncidentForParcelSync,
          WSRoomNames.DMA_APP_PARENT_ROOM_NAME_IA,
          updatePouchDBItem
        );
        joinRoom(
          WSRoomNames.DMA_APP_PA_ROOM_NAME_BASE + selectedIncidentForParcelSync,
          WSRoomNames.DMA_APP_PARENT_ROOM_NAME_PA,
          updatePouchDBItem
        );

        dispatch(setIsInitApiLoading(false));
        return navigate('/map');
      });
    }
  }, [token, auth, isApiLoading, wsConnection, selectedIncidentForParcelSync, dataFetched]);

  useEffect(() => {
    if (!selectedGroupID || !dataFetched) return;
    const controller = new AbortController();
    if (!selectedIncidentForParcelSync) {
      dispatch(setIsInitApiLoading(false));
      navigate('/info', {
        state: {
          message: 'Please set a data gathering incident in the main Damage Assessment program.',
          buttonText: 'RELOAD',
          action: 'reload',
        },
      });
    } else {
      const fetchAllTeamsAndGroup = async () => {
        dispatch(fetchAllTeams());
        return 'DONE';
      };

      if (groupNameForParcels) {
        dispatch(setNonParcelMode(groupNameForParcels));
      }

      fetchDataAndHandleErrors(fetchAllTeamsAndGroup).then(() => {
        navigate('/map');
      });
    }
    return () => controller.abort();
  }, [selectedIncidentForParcelSync, selectedGroupID, dataFetched]);

  if (isApiLoading) {
    return <Loading message={'App is loading, please wait...'} />;
  } else {
    return null;
  }
};

export default LoadingContainer;
